import React from "react"
import PropTypes from 'prop-types'
import { graphql } from "gatsby"
import InnerPageLayout from '../../layout/inner-page'
import Section, { Container, Box, Row, Col } from '../../../../shared-ui/components/ui/wrapper'
import SectionTitle from '../../../../shared-ui/components/ui/section-title'
import ServiceBox from '../../../../shared-ui/components/box-large-image/layout-two'
import Blog from '../../../../shared-ui/components/blog/layout-one'
import Heading from '../../../../shared-ui/components/ui/heading'
import Text from '../../../../shared-ui/components/ui/text'
import Button from '../../../../shared-ui/components/ui/button'
import BoxIcon from '../../../../shared-ui/components/box-icon/layout-two'
import AuthorMeta from '../../../../shared-ui/components/blog/author-meta'
import BlogMeta from '../../../../shared-ui/components/blog/blog-meta'
import { ServiceWrap, FeaturedServicesWrap } from './index.style'
import { IntroWrap, IntroHeading, IntroText, PricingWrap } from './index.style'
//import { renderRichText } from "gatsby-source-contentful/rich-text";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import Image from '../../../../shared-ui/components/image'
import Img from "gatsby-image"
import {
    SingleBlogWrap,
    BlogMedia,
    Thumbnail,
    Blockquote,
    CategoryBox,
    BlogInfo,
    HeaderMetaWrap,
    FooterMetaWrap,
    AuthorWrap,
    AuthorInfo,
    AuthorAvatar,
    AuthorDesc,
    BlogNavigation
} from './index.style'

const BlogPage = ({location, data, ...props }) => {  
    
  const bannerImage = data.bannerImage;  
  const blogPage = data.blogPage;

  const {
    sectionStyle,
    titleStyle,
    headerMetaStyle,
    authorStyles: { authorSocial, authorDesc } } = props;

  const aboutSectionTitleStyle = {
    mb: '60px',
    responsive: {
        small: {
            mb: '47px'
        }
    }
  };
  const aboutHeadingStyle = {
    layout: 'highlight',
    mr: '48px'
};
const aboutSubheadingStyle = {
    as: 'h6',
    mt: '30px'
};
const aboutDescStyle = {
    fontSize: '18px',
    mb: '20px'
};
  return (    
    <InnerPageLayout location={location} 
        bannerImg={bannerImage} browserTitle={`Blog: ${blogPage.title}`} metaImage={blogPage.images[0].fixed}
        pageData={{ title : blogPage.title, tagline : "Una oficina sin preocupaciones."}}> 

        <main className="site-wrapper-reveal">
                <Section {...sectionStyle}>
                    {<Row>
                        <Col lg={12}>
                            <BlogMedia>
                                 <Thumbnail>
                                        <Image fluid={blogPage.images[0].fluid} alt={blogPage.title} />
                                        <Img fixed={blogPage.images[0].fixed} alt={blogPage.title} />
                                 </Thumbnail>                                
                            </BlogMedia>
                        </Col>
                                </Row>}
                    <Row>
                        <Col lg={8} ml="auto" mr="auto">
                            <SingleBlogWrap>
                                <BlogInfo>
                                    <CategoryBox>
                                        {/*<Categories categories={categories} />*/}
                                    </CategoryBox>
                                    <Heading {...titleStyle}>{blogPage.title}</Heading>
                                    <HeaderMetaWrap>
                                        {/*author && (
                                            <AuthorMeta
                                                {...headerMetaStyle}
                                                slug={authorId}
                                                name={author.name}
                                                imageSrc={author.image.childImageSharp}
                                            />
                                        )*/}
                                        {blogPage.publicPublishedDate && (
                                            <BlogMeta
                                                {...headerMetaStyle}
                                                //path={`/date/${dateSlug}`}
                                                text={blogPage.publicPublishedDate}
                                                icon={"fa fa-calendar-alt"}
                                            />
                                        )}
                                        {/*<BlogMeta
                                            {...headerMetaStyle}
                                            path={`/`}
                                            text={`4 Comments`}
                                            icon="fa fa-comment-dots"
                                        />*/}
                                    </HeaderMetaWrap>
                                </BlogInfo>
                                <div className="post-content" >
                                    {renderRichText(blogPage.mainContent)}   
                                </div>                                
                                <Box as="footer">
                                     {/*<FooterMetaWrap>
                                        <Row alignitems="center">
                                            <Col sm={6}>
                                                <Tags tags={tags} />
                                            </Col>
                                            <Col sm={6}>
                                                <SocialShare
                                                    title={post.title}
                                                    url={slug}
                                                />
                                            </Col>
                                        </Row>
                                    </FooterMetaWrap>
                                   <AuthorWrap>
                                        <AuthorInfo>
                                            <AuthorAvatar>
                                                <Image fluid={author.image.childImageSharp.fluid} alt={author.name} />
                                                {author.social && (
                                                    <Social {...authorSocial}>
                                                        {author.social.twitter && (
                                                            <SocialLink
                                                                path={author.social.twitter}
                                                                label="Twitter">
                                                                <i className="fa fa-twitter"></i>
                                                            </SocialLink>
                                                        )}
                                                        {author.social.facebook && (
                                                            <SocialLink
                                                                path={author.social.facebook}
                                                                label="Facebook">
                                                                <i className="fa fa-facebook"></i>
                                                            </SocialLink>
                                                        )}
                                                        {author.social.linkedin && (
                                                            <SocialLink
                                                                path={author.social.linkedin}
                                                                label="Linkedin">
                                                                <i className="fa fa-linkedin"></i>
                                                            </SocialLink>
                                                        )}
                                                        {author.social.instagram && (
                                                            <SocialLink
                                                                path={author.social.instagram}
                                                                label="Instagram">
                                                                <i className="fa fa-instagram"></i>
                                                            </SocialLink>
                                                        )}
                                                    </Social>
                                                )}
                                            </AuthorAvatar>
                                            <AuthorDesc>
                                                {author.name && <Heading as="h6">{author.name}</Heading>}
                                                {author.bio && <Text {...authorDesc}>{author.bio}</Text>}
                                            </AuthorDesc>
                                        </AuthorInfo>
                                     </AuthorWrap>
                                    <BlogNavigation>
                                        {previous && (
                                            <PostNav
                                                rel="prev"
                                                path={previous.fields.slug}
                                                title={previous.frontmatter.title}
                                                image={previous.frontmatter.featured_image ? previous.frontmatter.featured_image.childImageSharp.fluid : null}
                                            />
                                        )}
                                        {next && (
                                            <PostNav
                                                rel="next"
                                                path={next.fields.slug}
                                                title={next.frontmatter.title}
                                                image={next.frontmatter.featured_image ? next.frontmatter.featured_image.childImageSharp.fluid : null}
                                            />
                                        )}
                                    </BlogNavigation>*/}
                                </Box>
                            </SingleBlogWrap>
                        </Col>
                    </Row>
                    {/*<Row>
                        <Col lg={8} ml="auto" mr="auto">
                            <Comment
                                url={slug}
                                id={id}
                                title={post.title}
                            />
                        </Col>
                    </Row>*/}
                </Section>
            </main>
                
    </InnerPageLayout>)
}

BlogPage.propTypes = {
    sectionStyle: PropTypes.object
}

BlogPage.defaultProps = {
    aboutSectionTitleStyle: {
        mb: '60px',
        responsive: {
            small: {
                mb: '47px'
            }
        }
    },
    aboutHeadingStyle: {
        layout: 'highlight',
        mr: '48px'
    },
    aboutSubheadingStyle: {
        as: 'h6',
        mt: '30px'
    },
    aboutDescStyle: {
        fontSize: '18px',
        mb: '20px'
    },
    aboutBtnStyle: {
        varient: 'texted',
        icondistance: '4px'
    },
    sectionStyle: {
        pt: '120px',
        pb: '120px',
        responsive: {
            large: {
                pt: '100px',
                pb: '100px'
            },
            medium: {
                pt: '80px',
                pb: '80px'
            },
            small: {
                pt: '60px',
                pb: '60px',
                fullwidth: true
            }
        }
    },
    titleStyle: {
        as: 'h1',
        fontSize: '40px',
        textalign: 'center',
        mb: '15px',
        wordwrap: 'break-word',
        responsive: {
            medium: {
                fontSize: '35px'
            },
            small: {
                fontSize: '25px'
            }
        }
    },
    headerMetaStyle: {
        mt: '10px',
        mr: '20px'
    },
    authorStyles: {
        authorSocial: {
            fontSize: '13px',
            color: '#333',
            space: 14
        },
        authorDesc: {
            mt: '15px',
            fontSize: '15px',
            color: '#333'
        }
    }
}
 
export default BlogPage

export const pageQuery = graphql`
  query GetBlogPageContent($urlCode: String!) {
    bannerImage : file(relativePath: { eq: "legacy_images/parallax-virtual-office2.jpg" }) {
        childImageSharp {
           gatsbyImageData(
                width: 1024
                placeholder: BLURRED
                formats: [AUTO, WEBP, AVIF]
           )
        }
      }
    blogPage : contentfulBlog(urlCode : {eq : $urlCode}){
        id
        title
        urlCode
        publicPublishedDate  
        summary {
            summary
            childMarkdownRemark {
                html
            }
        } 
        mainContent {
            raw
        }
        images {
            fluid(maxWidth: 1024, maxHeight: 500, quality: 100) {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyContentfulFluid
                }    
                fixed(width: 1024, height:500, quality: 100) {
                    # Choose either the fragment including a small base64ed image, a traced placeholder SVG, or one without.
                    ...GatsbyContentfulFixed
                }            
        }
    }        
  }
`
